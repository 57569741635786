import React from 'react';
import { Box, type BoxProps } from 'bb/ui/Box';
import { type PolymorphicComponent } from 'bb/ui/types';

export type PaddedBoxProps<TElementType extends React.ElementType = 'div'> =
    PolymorphicComponent<TElementType, Omit<BoxProps<TElementType>, 'padding'>>;

/**
 * A composition of the `Box` component with a standard set of
 * padding added. Commonly used as a section-type component.
 */
export const PaddedBox = ((props: PaddedBoxProps<'div'>) => {
    const { children, as = 'div', ref, ...restProps } = props;

    return (
        <Box
            {...restProps}
            as={as}
            ref={ref}
            padding={{ xxs: [8, 4, 8, 4], sm: [12, 6, 12, 6] }}
        >
            {children}
        </Box>
    );
}) as <TElementType extends React.ElementType = 'div'>(
    props: PaddedBoxProps<TElementType>
) => JSX.Element;
