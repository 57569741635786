import React from 'react';
import NextLink from 'next/link';
import { type Router } from 'next/router';
import { useApp } from 'bb/app/AppContext';
import { useRouter, type Routes } from 'bb/app/router';
import { useGetRoutesFromRemappedRoutes } from 'bb/app/router/useGetRoutesFromRemappedRoutes';
import { getUrl } from 'bb/app/router/utils';
import { type PolymorphicComponent } from 'bb/ui';
import {
    Button,
    type TextButtonProps,
    type ButtonProps,
    type ButtonVariant,
    TextButton
} from 'bb/ui/Button';

export type LinkButtonVariant = ButtonVariant | 'text';

export type LinkButtonProps<
    TButtonVariant extends LinkButtonVariant = 'contained'
> = PolymorphicComponent<
    'a',
    {
        route: keyof Routes;
        query?: Router['query'];
        reload?: boolean;
        variant?: TButtonVariant;
    } & Omit<
        TButtonVariant extends 'text'
            ? TextButtonProps<'a'>
            : ButtonProps<Exclude<TButtonVariant, 'text'>, 'a'>,
        'variant'
    >
>;

export const LinkButton = (({
    route: routeName,
    query,
    children,
    reload,
    variant = 'contained',
    ref,
    ...restProps
}: LinkButtonProps<LinkButtonVariant>) => {
    const { routes } = useRouter();
    const getRouteFromRemappedRoutes = useGetRoutesFromRemappedRoutes();
    const { market: appMarket } = useApp();
    const market = query?.market ?? appMarket;

    const route =
        routes[getRouteFromRemappedRoutes(routeName)] ?? routes[routeName];

    const buttonProps: Partial<
        TextButtonProps<'a'> | ButtonProps<'contained', 'a'>
    > = {
        ...restProps,
        as: 'a',
        onClick: reload
            ? (...args) => {
                  window.location.href = `${window.location.origin}${getUrl(route.href, { ...query, market })}`;

                  restProps.onClick?.(...args);
              }
            : restProps.onClick
    };

    return (
        <NextLink
            prefetch={false}
            passHref
            href={{
                pathname: route.href,
                query: { ...query, market }
            }}
            locale=""
            legacyBehavior
        >
            {variant === 'text' ? (
                <TextButton {...buttonProps} ref={ref}>
                    {children}
                </TextButton>
            ) : (
                <Button {...buttonProps} variant={variant} ref={ref}>
                    {children}
                </Button>
            )}
        </NextLink>
    );
}) as <TButtonVariant extends LinkButtonVariant = 'contained'>(
    props: LinkButtonProps<TButtonVariant>
) => JSX.Element;
