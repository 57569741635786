import React from 'react';
import classnames from 'classnames';
import { useRouter } from 'bb/app/router';
import { useTranslation } from 'bb/i18n';
import {
    ArrowLeftIcon,
    DeprecatedButton,
    type ButtonProps,
    type PolymorphicComponent
} from 'bb/ui';
import { GO_BACK_BUTTON_CLASSNAME } from './consts';

export type GoBackButtonProps<T extends React.ElementType> =
    PolymorphicComponent<T, Omit<ButtonProps<'text'>, 'variant' | 'size'>>;

export const GoBackButton = (props: GoBackButtonProps<'button'>) => {
    const { router } = useRouter();
    const { t } = useTranslation(['common']);
    const {
        children = t('common:navigateBack'),
        onClick = router.back,
        as = 'button',
        className,
        ref,
        ...restProps
    } = props;

    return (
        <DeprecatedButton
            as={as}
            onClick={onClick}
            ref={ref}
            variant="text"
            size="small"
            icon={<ArrowLeftIcon />}
            iconSize="small"
            className={classnames(GO_BACK_BUTTON_CLASSNAME, className)}
            {...restProps}
        >
            {children}
        </DeprecatedButton>
    );
};
