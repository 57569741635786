import striptags from 'striptags';
import {
    makeOpenAppScheme,
    type OpenAppHref
} from 'bb/common/components/buttons';
import { type Market } from 'bb/config';
import {
    type Book,
    type Contributor,
    ContributorRole,
    type Edition
} from 'bb/discovery/book/types';
import { type SeriesResponse } from 'bb/discovery/types';
import { type TFunction } from 'bb/i18n';
import { uniqueByKey } from 'bb/utils';
import { isProductionEnvironment } from 'bb/utils/environment';

const contributorFilter = (role: ContributorRole) => (c: Contributor) =>
    c.role === role;

export const getContributors = (editions: Edition[] = []) =>
    uniqueByKey(
        editions
            .flatMap((e) => e.contributors)
            .filter((c): c is Contributor => Boolean(c)),
        'id',
        'role'
    );

export const getNarrators = (contributors: Contributor[] = []) =>
    contributors.filter(contributorFilter(ContributorRole.NARRATOR));

export const getTranslators = (contributors: Contributor[] = []) =>
    contributors.filter(contributorFilter(ContributorRole.TRANSLATOR));

export const getAuthors = (contributors: Contributor[] = []) =>
    contributors.filter(contributorFilter(ContributorRole.AUTHOR));

export const getEditors = (contributors: Contributor[] = []) =>
    contributors.filter(contributorFilter(ContributorRole.EDITOR));

export const getFirstAuthor = (contributors: Contributor[] = []) =>
    getAuthors(contributors)[0];

export const getAuthorsString = (
    numberOfAuthors: number,
    contributors: Contributor[] = []
) =>
    getAuthors(contributors)
        .slice(0, numberOfAuthors)
        .map((a) => a.displayname)
        .join(', ');

export const getBooksInSeries = (series: SeriesResponse) =>
    series?._embedded?.parts?.map((part) => part._embedded.book) || null;

export const getBookMetaTitle = (
    title: string,
    contributors: Contributor[] = [],
    formats: string[] = []
) => {
    const authorName = getFirstAuthor(contributors)?.displayname;
    const formatString = formats.join(' - ');
    const parts = [title, authorName, formatString, 'BookBeat'];
    return parts.filter((p) => p).join(' - ');
};

export const getBookMetaDescription = (description: string) => {
    if (!description) {
        return '';
    }

    const REGEXP_HTML_WHITESPACE = /\u00a0/g;
    const REGEXP_MULTI_WHITESPACE = /\s\s+/g;

    const cleaned = striptags(description)
        .replace(REGEXP_HTML_WHITESPACE, ' ')
        .replace(REGEXP_MULTI_WHITESPACE, ' ')
        .trim();

    return `${cleaned.substring(0, 260)}...`;
};

// Helper function to calculate hours from seconds
export const getHoursFromSeconds = (seconds: number) =>
    Math.floor(seconds / 3600);

// Helper function to calculate minutes from remaining seconds
export const getMinutesFromRemainingSeconds = (remainingSeconds: number) =>
    Math.floor(remainingSeconds / 60);

export const calculateTimeParts = (seconds: number) => {
    const hours = getHoursFromSeconds(seconds);
    const remainingTime = seconds - hours * 3600;
    const minutes = getMinutesFromRemainingSeconds(remainingTime);

    return { hours, minutes };
};

/*
 * This function formats time to a short string with hours and minutes.
 * For example, it might return a string like "2h 30min".
 */
export const shortFormat = (seconds: number, t: TFunction<'book'>) => {
    const { hours, minutes } = calculateTimeParts(seconds);

    const hoursPart = hours ? t('book:hours', { hours }) : '';
    const minutesPart = minutes ? t('book:minutes', { minutes }) : '';

    return `${hoursPart} ${minutesPart}`.trim();
};

/*
 * This function formats time to a long string with hours and minutes.
 * For example, it might return a string like "2 hours 30 minutes".
 */
export const longFormat = (seconds: number, t: TFunction<'common'>) => {
    const { hours, minutes } = calculateTimeParts(seconds);

    // Translates without displaying the number, only choosing singular or plural form
    const hoursLabel = t('common:hours', { periodLength: hours });
    const minutesLabel = t('common:minutes', { periodLength: minutes });

    // Return formatted time with number and corresponding label
    return `${hours} ${hoursLabel} ${minutes} ${minutesLabel}`.trim();
};

/*
 * Formats a book summary by replacing <br> tags with paragraph tags and removing empty paragraphs.
 * This is to ensure that the summary is more accessible, especially for screen readers.
 *
 * @param {string | undefined} summary - The original summary text that may contain <br> tags or be undefined.
 * @returns {string} The formatted summary with <br> tags replaced and empty paragraphs removed, or an empty string if summary is undefined.
 */
export const formatSummary = (summary: string | undefined): string => {
    if (!summary) return '';

    // If the summary already contains <p> tags, we assume it's correctly formatted
    if (summary.startsWith('<p>') && summary.endsWith('</p>')) {
        return summary;
    }

    // Replace <br> tags with </p><p> tags
    let formattedSummary = `<p>${summary.replace(/<br\s*\/?>/g, '</p>\n<p>')}</p>`;

    // Remove empty paragraphs including those with just spaces or new lines
    formattedSummary = formattedSummary.replace(/<p>\s*<\/p>\n*/g, '');

    return formattedSummary;
};

export const getStructuredData = (
    book: Book,
    authors: Contributor[],
    bookMetaDescription: string,
    canonicalUrl: string
) => ({
    '@context': 'http://schema.org',
    '@type': 'Book',
    '@id': book?.id?.toString(),
    name: book?.title,
    url: canonicalUrl,
    author: authors?.map((c) => ({
        '@type': 'Person',
        name: c.displayname
    })),
    aggregateRating: book?.rating?.numberOfRatings
        ? {
              '@type': 'AggregateRating',
              ratingValue: book?.rating?.ratingValue || 0,
              reviewCount: book?.rating?.numberOfRatings || 0,
              itemReviewed: {
                  '@type': 'Thing',
                  name: book?.title,
                  subjectOf: {
                      '@type': 'Book',
                      name: book?.title
                  }
              }
          }
        : null,
    image: book?.cover,
    description: bookMetaDescription,
    workExample: book?.editions?.map((e) => ({
        '@type': 'Book',
        '@id': e.id?.toString(),
        bookFormat:
            e.format === 'eBook'
                ? 'https://schema.org/EBook'
                : 'https://schema.org/AudiobookFormat',
        isbn: e.isbn,
        datePublished: e.published?.slice(0, 10),
        publisher: {
            '@type': 'Organization',
            name: e.publisher
        },
        potentialAction: {
            '@type': 'ReadAction',
            target: {
                '@type': 'EntryPoint',
                urlTemplate: canonicalUrl,
                actionPlatform: [
                    'https://schema.org/AndroidPlatform',
                    'https://schema.org/IOSPlatform'
                ]
            }
        }
    }))
});

export const createDataLayerBook = (book: Book) => {
    if (!book) return {};

    const audioBook = book.editions?.find((e) => e.format === 'audioBook');
    const dataLayerAudioBook = audioBook && {
        audioBookLength: book.audiobooklength,
        copyright: audioBook.copyrightOwners?.map((c) => c.name).join(),
        isbn: audioBook.isbn,
        publisher: audioBook.publisher
    };

    const ebook = book.editions?.find((e) => e.format === 'eBook');
    const dataLayerEBook = ebook && {
        copyright: ebook.copyrightOwners?.map((c) => c.name).join(),
        eBookLength: book.ebooklength,
        isbn: ebook.isbn,
        publisher: ebook.publisher
    };

    const dataLayerSeries = book.series && {
        count: book.series.count,
        id: book.series.id,
        name: book.series.name,
        partnumber: book.series.partnumber,
        url: book.series.url
    };

    const contributors = getContributors(book.editions);
    const dataLayerAuthors = getAuthors(contributors).map((a) => a.displayname);
    const dataLayerNarrators = getNarrators(contributors).map(
        (a) => a.displayname
    );
    const dataLayerTranslators = getTranslators(contributors).map(
        (a) => a.displayname
    );

    return {
        id: book.id,
        title: book.title,
        summary: book.summary,
        language: book.language,
        published: book.published,
        grade: book.grade,
        cover: book.cover,
        audiobook: dataLayerAudioBook || null,
        ebook: dataLayerEBook || null,
        genres: book.genres?.map((g) => ({
            booksurl: g.booksurl,
            genreid: g.genreid,
            name: g.name,
            parentid: g.parentid
        })),
        narrators: dataLayerNarrators,
        authors: dataLayerAuthors,
        translator: dataLayerTranslators,
        series: dataLayerSeries,
        rating: {
            numberOfRatings: book.rating?.numberOfRatings,
            ratingValue: book.rating?.ratingValue
        },
        badges: book.badges
    };
};

export const showSeriesPart = (book: Book) => {
    const { nextbookid, prevbookid, partindex } = book?.series ?? {};
    if ((nextbookid || prevbookid) && typeof partindex === 'number') {
        return partindex > 0;
    }
    return false;
};

export const getAppHrefByMarket = (
    market: Market,
    book: Book
): OpenAppHref | undefined =>
    `${makeOpenAppScheme()}${
        isProductionEnvironment() ? 'www' : 'test'
    }.bookbeat.com/${market}/book/${book.id}`;

/**
 * Currently the iOS app only supports localized routes internally.
 * So when we open the app from the web we need to use the localized
 * route. This can be accomplished by using the shareurl and replacing
 * the scheme with the app scheme.
 *
 * Ideally this solution should be removed when the iOS app can handle
 * non-localized routes.
 */
export const makeOpenInAppHref = (
    shareUrl: Book['shareurl']
): OpenAppHref | null => {
    if (!shareUrl) return null;

    return shareUrl.replace('https://', makeOpenAppScheme()) as OpenAppHref;
};
